import { ElMessage } from 'element-plus';
import { format, parseISO } from 'date-fns';
import getMessage from '@/config/utils/messages';

const lang = 'fr';

export default class Utilities {
  static showMessage(type, key) {
    return ElMessage({
      message: getMessage(lang, key),
      type,
      offset: 200,
    });
  }

  static getISOdate(iso) {
    return iso.toISOString();
  }
  static formatDate(date) {
    const newDate = typeof date !== 'string' ? this.getISOdate(date) : date;
    return format(parseISO(newDate), 'dd/MM/yyyy');
  }

  static formatTime(date) {
    const newDate = typeof date !== 'string' ? this.getISOdate(date) : date;
    return format(parseISO(newDate), 'HH:mm');
  }

  static async getPageTexts(page) {
    const { default: texts } = await import(`@/config/pages/${page}`);
    return texts;
  }
}
