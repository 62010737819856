<template>
  <div class="container app-container full-height">
    <slot name="back" />

    <div class="row text-center">
      <div class="content" :class="colWidth">
        <div class="content__img">
          <span class="material-symbols-outlined">
            {{ icon }}
          </span>
        </div>
        <div class="content__title">
          <slot name="header" />
        </div>
        <div class="content__body">
          <slot name="body" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppContainer',
  props: ['icon', 'colWidth'],
};
</script>

<style lang="scss">
.app-container {
  padding: 4rem 0rem;
  .row {
    .content {
      padding: $sp-2x $sp-2x !important;
      margin: 0 auto;
      border-radius: 1rem;
      color: $dark;
      background-color: white;
      margin-bottom: calc($sp / 2);
      box-shadow: $shadow-default;
      padding-bottom: 1.5rem;
      &__title {
        margin-top: -3rem;
        h1 {
          font-size: 18px !important;
        }
      }
      &__img {
        background-color: $light-grey;
        width: 7rem;
        height: 7rem;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        transform: translateY(-4.5rem);
        span {
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 4.5rem;
          width: auto;
        }
      }
    }
  }
}
</style>
