import email_domains from "@/config/utils/email_domains";

export default class Validator {
  static validateField(value, rule) {
    switch (rule.type) {
      case 'string':
        return this.validateString(value, rule);
      case 'email':
        return this.validateEmail(value, rule);
      case 'domain':
        return this.validateDomain(value, rule);
      case 'phone':
        return this.validatePhone(value, rule);
      case 'zipcode':
        return this.validateZipcode(value, rule);
      case 'minLength':
        return this.validateMinLength(value, rule);
      case 'maxLength':
        return this.validateMaxLength(value, rule);
      case 'required':
        return this.validateRequired(value, rule);
      case 'confirm':
        return this.validateConfirm(value, rule, rule.comparedValue);
      case 'fileType':
        return this.validateFileType(value, rule);
      case 'number':
        return this.validateStringNumber(value, rule);
      case 'password':
        return this.validatePassword(value, rule);
      default:
        return '';
    }
  }

  static validateStringNumber(value, rule) {
    if (!value.split('').some((char) => /\d/.test(char))) return rule.message;
  }

  static validateEmail(value, rule) {
    if (!/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(value))
      return rule.message;
  }

  static validateDomain(value, rule) {
    const domain = value.split('@')[1];
    if (!email_domains.includes(domain)) return rule.message;
  }

  static validatePhone(value, rule) {
    if (
      !/^\d{2}[-.\s]?\d{2}[-.\s]?\d{2}[-.\s]?\d{2}[-.\s]?\d{2}$/.test(
        value
      )
    )
      return rule.message;
  }

  static validateZipcode(value, rule) {
    if (!/^(?:0[1-9]|[1-8]\d|9[0-8])\d{3}$/.test(value)) return rule.message;
  }

  static validateConfirm(value, rule, password) {
    if (value !== password) return rule.message;
  }

  static validateMinLength(value, rule) {
    if (value.length < rule.minLength) return rule.message;
  }

  static validateMaxLength(value, rule) {
    if (value.length > rule.maxLength) return rule.message;
  }

  static validateRequired(value, rule) {
    if (value === '') return rule.message;
  }

  static validateFileType(value, rule) {
    if (value) {
      const file = value[0];
      const fileType = file.type.split('/')[0];
      if (!rule.fileTypes.includes(fileType)) return rule.message;
    }
  }

  static validateString(value, rule) {
    if (typeof value !== 'string') return rule.message;
  }

  static validatePassword(value, rule) {
    const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

    if (!passwordPattern.test(value)) {
      return rule.message;
    }
  }

  static validateForm(formValues, formRules) {
    let isValid = true;

    Object.keys(formValues).forEach((key) => {
      const value = formValues[key];
      const rules = formRules[key];

      rules.forEach((rule) => {
        const error = this.validateField(value, rule);
        if (error) isValid = false;
      });
    });

    return isValid;
  }
}
