<template>
  <div class="row back-link">
    <div class="back-link__col" :class="colWidth">
      <router-link :to="{ name: routeName }">
        <img src="@/assets/img/back.png" /> {{ text }}
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppBack',
  props: ['text', 'routeName', 'colWidth'],
};
</script>

<style lang="scss">
.back-link {
  &__col {
    margin: 0 auto;
    align-items: center;
    padding-bottom: 0.5rem;
    a {
      color: $light !important;
    }
  }
}
</style>
