const utilities = {
    image_type_error: {
        en: 'Only JPG, JPEG & PNG files are allowed',
        fr: 'Seuls les fichiers JPG, JPEG et PNG sont autorisés',
    },
    empty_fields_error: {
        en: 'Please fill in all fields',
        fr: 'Veuillez vérifier tous les champs.',
    },
    copy_paste_error: {
        en: 'Copy/paste is not allowed',
        fr: "Le copier/coller n'est pas autorisé",
    },
    register_error: {
        en: 'An error occured while registering.',
        fr: "Une erreur est survenue lors de l'enregistrement.",
    },
    login_error: {
        en: 'An error occured while trying to log you in. Please check your credentials.',
        fr: 'Une erreur est survenue. Veuillez vérifier vos identifiants ou vérifier votre adresse mail.',
    },
    user_not_found_error: {
        en: 'It seems like you do not have an account yet. Please register first.',
        fr: 'Aucun compte utilisateur n\'a été trouvé. Veuillez vous inscrire.'
    },
    logout_error: {
        en: 'An error occured while trying to log you out. ',
        fr: 'Une erreur est survenue lors de la déconnexion.'
    },
    forgot_password_success: {
        en: 'An email has been sent to you with a link to reset your password.',
        fr: 'Un email vous a été envoyé avec un lien pour réinitialiser votre mot de passe.',
    },
    forgot_password_error: {
        en: 'An error occured while trying to send you an email.',
        fr: "Une erreur est survenue lors de l'envoi de l'email de réinitialisation.",
    },
    reset_password_success: {
        en: 'Your password has been reset successfully.',
        fr: 'Votre mot de passe a été réinitialisé avec succès.',
    },
    reset_password_error: {
        en: 'An error occured while trying to reset your password.',
        fr: 'Une erreur est survenue lors de la réinitialisation de votre mot de passe.',
    },
    update_profile_error: {
        en: 'An error occured while trying to update your profile.',
        fr: "Une erreur s'est produite lors de la mise à jour de votre profil",
    },
    update_profile_success: {
        en: 'Your profile has been updated successfully.',
        fr: 'Votre profil a été mis à jour avec succès.',
    },
    create_report_error: {
        en: 'An error occured while trying to create a report.',
        fr: 'Une erreur est survenue lors de la création de votre FDES.'
    },
    token_not_existed: {
        en: 'The link has expired.',
        fr: `Votre lien a expiré.`
    }
};

export default function getMessage(lang, type) {
    return utilities[type][lang];
}
