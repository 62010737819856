import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './utils/styles';
import GlobalComponents from './utils/globals';
import ElementPlus from 'element-plus';

import interceptors from './utils/interceptors';

interceptors();

const app = createApp(App);
app.use(store);
app.use(router);
app.use(GlobalComponents);
app.use(ElementPlus);
app.mount('#app');
